/* App container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #212121;
  align-items: center; 
  justify-content: center;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  box-sizing: border-box;
  color: #ececec;
  text-align: center;
}

/* Image preview container */
.image-preview-container {
  margin: 20px 0;
  text-align: center;
}

.image-preview {
  min-height: 45vh;
  max-height: 50vh;
  max-width: 80vw;
  border-radius: 15px;
  object-fit: contain;
}

/* Results container */
.results-container {
  text-align: center;
  margin-top: 20px;
  flex-grow: 1;
}

/* Analyzing text */
.analyzing-text {
  font-size: 20px;
  font-style: italic;
}

/* Error text */
.error-text {
  color: red;
  font-weight: bold;
}

/* Car data styling */
.car-data {
  text-align: left;
  background-color: #2f2f2f;
  border-radius: 15px;
  padding: 10px 20px;
  max-width: 400px;
}

/* Footer styling */
.footer {
  margin-top: auto;
  text-align: center;
  background-color: #171717;
  padding: 10px 0;
  width: 100%;
  flex-shrink: 0;
  font-size: 12px;
  color: #777;
}

/* Custom file input container */
.file-input-container {
  position: relative;
  margin-bottom: 20px;
}

/* Hide the default file input */
.hidden-file-input {
  display: none;
}

/* Style the custom file input button */
.custom-file-input {
  display: inline-block;
  background-color: #ececec;
  color: #171717;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-file-input:hover {
  background-color: #aaa;
}

/* Analyzing container styling */
.analyzing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Loading spinner */
.loading-spinner {
  border: 8px solid #171717;
  border-top: 8px solid #ececec;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}